@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
body {
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  background-image: url('./images/dj-jay-bg.jpg');
  background-size: 100% auto;
  background-position-y: -300px;
}
.page-hero {
  height: 7vw;
  background-color: grey;
  background:
  radial-gradient(black 15%, transparent 16%) 0 0,
  radial-gradient(black 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828;
  background-size:16px 16px;
  min-height: 100px;
}
.top-header {
  background-color: black;
  border-bottom: 1px solid red;
  text-transform: capitalize;
}
.text-shadow {
  text-shadow: 2px 2px 2px #000000;
}
.text-red {
  color: red;
}
.type-text {
  font-weight: bold;
}
.badge-outline {
  background-color: transparent;
  border: 1px solid #FFF;
  font-size: .925em;
  font-weight: normal;
  margin: 3px;
}
.video-container {
  max-width: 350px;
  overflow: hidden;
  border-radius: .25em;
}
.btn-view-more-works {
  display: none !important;
}
.home-page .btn-view-more-works {
  display: flex !important;
}
.gallery-image {
  width: 100%;
}